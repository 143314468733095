import React from 'react'
import { css } from 'linaria'
import GoogleMapReact from 'google-map-react'
import { Meta } from 'react-head'

import Layout from '../components/Layout'
import Section from '../components/Section'
import SectionContent from '../components/Section/Content'

import { Lead, Subheading } from '../components/TextElements'
import rhythm from '../utils/rhythm'
import { colors } from '../utils/theme'
import SocialMedia from '../components/Footer/SocialMedia'
import { ReactComponent as SmallLogo } from '../assets/svg/logo-small-white.svg'
import { ReactComponent as LetterIcon } from '../assets/svg/icons/letter.svg'
import { ReactComponent as PhoneIcon } from '../assets/svg/icons/phone.svg'

import hexToRgb from '../utils/hexToRgb'
import breakpoints from '../utils/tokens/breakpoints'
import sendEvent from '../utils/sendEvent'

const emailsList = css`
  list-style: none;
  margin: 0;
  padding: 0;
  color: ${colors.text.lightGray};
  font-size: 1.125rem;

  li + li {
    margin-top: ${rhythm(1)};
  }
`

const addressBlock = css`
  font-style: normal;
  color: ${colors.text.darkGray};
  font-size: 1.125rem;

  span {
    color: ${colors.text.lightGray};
  }
`

const subheading = css`
  margin-bottom: ${rhythm(2 / 3)};
  color: ${colors.text.lightGray};
`

const socials = css`
  list-style: none;
  margin: ${rhythm(5 / 2)} 0 0;
  justify-content: flex-start;

  a {
    color: ${colors.text.darkGray};
  }

  li + li {
    margin-left: ${rhythm(2)};
  }

  svg {
    transform: scale(1.5);
  }
`

const markerClass = css`
  width: 2rem;
  height: 2rem;
  border: 2px solid #fff;
  background: ${colors.accent.purple};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 rgba(${hexToRgb(colors.accent.purple)}, 0.4);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(${hexToRgb(colors.accent.purple)}, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(${hexToRgb(colors.accent.purple)}, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(${hexToRgb(colors.accent.purple)}, 0);
    }
  }
`

const linkClass = css`
  color: ${colors.accent.purple};
  text-decoration: none;
`

const letterIcon = css`
  position: relative;
  top: 0.25rem;
  margin-right: ${rhythm(1 / 2)};
`

const Marker = () => (
  <div className={markerClass}>
    <SmallLogo viewBox="0 0 317.758 283.971" width="12" height="12" />
  </div>
)

const ContactEmails = () => (
  <div>
    <ul className={emailsList}>
      <li>
        <Subheading tag="p" variant="default" className={subheading}>
          New business
        </Subheading>
        <LetterIcon className={letterIcon} />
        <a
          href="mailto:sales@bejamas.io"
          className={linkClass}
          onClick={() => sendEvent({ label: 'sales@bejamas.io' })}
        >
          sales@bejamas.io
        </a>
      </li>
      <li>
        <Subheading tag="p" variant="default" className={subheading}>
          Want to join us?
        </Subheading>
        <LetterIcon className={letterIcon} />
        <a
          href="mailto:hr@bejamas.io"
          className={linkClass}
          onClick={() => sendEvent({ label: 'hr@bejamas.io' })}
        >
          hr@bejamas.io
        </a>
      </li>
    </ul>
    <SocialMedia className={socials} />
  </div>
)

const Page = ({ location }) => (
  <Layout
    location={location}
    title="Let's Talk"
    description="Tell us about your project, get a quick estimate and a plan for making it live."
    image="https://bejamas-care.now.sh/**Let's%20Talk**.png?theme=light-v1&md=1&fontSize=100px&images=https%3A%2F%2Fassets-bejamas.now.sh%2Fbrand%2Fsvg%2FBejamas_logo_black.svg"
    showCtaInHeader={true}
    jsonLD={`
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Bejamas",
        "description": "JAMstack Developers for hire. A Web Dev shop focused on helping agencies and companies with serverless web, Static Site Generators & JAMstack tools.",
        "image": "https://bejamas.io/icons/icon-512x512.png",
        "logo": "https://bejamas.io/icons/icon-512x512.png",
        "url": "https://bejamas.io/",
        "telephone": "+48690442069",
        "sameAs": ["https://twitter.com/bejamas_io","https://www.linkedin.com/company/bejamas/","https://www.facebook.com/bejamas.io"],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Szczytnicka 11",
          "addressLocality": "Wrocław",
          "postalCode": "50-382",
          "addressCountry": "Poland"
        }
      }
    `}
  >
    <Section>
      <SectionContent
        wide
        rowGap="0"
        className={css`
          ${{
            marginTop: 0,
            gridTemplateColumns: '100%',
            [`@media (min-width: ${breakpoints.sm})`]: {
              gridTemplateColumns: '65%'
            },
            [`@media (min-width: ${breakpoints.lg})`]: {
              gridTemplateColumns: '50%'
            }
          }}
        `}
      >
        <h1>Let&apos;s Talk</h1>
        <Lead>
          Tell us about your project, get a quick estimate and a plan for making
          it live.
        </Lead>
      </SectionContent>
      <SectionContent
        wide
        rowGap="2"
        className={css`
          ${{
            marginTop: rhythm(1),
            gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))'
          }}
        `}
      >
        <ContactEmails />

        <address className={addressBlock}>
          <Subheading tag="p" variant="default" className={subheading}>
            Headquarters
          </Subheading>
          Bejamas Group Sp. z o.o.
          <br />
          Business Link Green2Day
          <br />
          Szczytnicka 11
          <br />
          50-382 Wroclaw
          <br />
          Poland
          <p
            className={css`
              ${{ marginTop: rhythm(1) }}
            `}
          >
            <PhoneIcon className={letterIcon} />
            <a
              href="tel:+48690442069"
              className={linkClass}
              onClick={() => sendEvent({ label: 'Phone +48 690 442 069' })}
            >
              +48 690 442 069
            </a>
          </p>
        </address>
        <div
          className={css`
            ${{
              height: '450px',
              width: '100%',
              backgroundImage: 'url(/img/contact-map-bg.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              [`@media (max-width: ${breakpoints.lg})`]: {
                gridColumn: '1 / -1'
              }
            }}
          `}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyAlIUk66LdaJPf2_5jV4av8ri1LIfqrr7w'
            }}
            defaultCenter={{
              lat: 51.113354,
              lng: 17.052612
            }}
            defaultZoom={13}
            options={{
              styles: [
                {
                  featureType: 'water',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#e9e9e9'
                    },
                    {
                      lightness: 17
                    }
                  ]
                },
                {
                  featureType: 'landscape',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#f5f5f5'
                    },
                    {
                      lightness: 20
                    }
                  ]
                },
                {
                  featureType: 'road.highway',
                  elementType: 'geometry.fill',
                  stylers: [
                    {
                      color: '#ffffff'
                    },
                    {
                      lightness: 17
                    }
                  ]
                },
                {
                  featureType: 'road.highway',
                  elementType: 'geometry.stroke',
                  stylers: [
                    {
                      color: '#ffffff'
                    },
                    {
                      lightness: 29
                    },
                    {
                      weight: 0.2
                    }
                  ]
                },
                {
                  featureType: 'road.arterial',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#ffffff'
                    },
                    {
                      lightness: 18
                    }
                  ]
                },
                {
                  featureType: 'road.local',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#ffffff'
                    },
                    {
                      lightness: 16
                    }
                  ]
                },
                {
                  featureType: 'poi',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#f5f5f5'
                    },
                    {
                      lightness: 21
                    }
                  ]
                },
                {
                  featureType: 'poi.park',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#dedede'
                    },
                    {
                      lightness: 21
                    }
                  ]
                },
                {
                  elementType: 'labels.text.stroke',
                  stylers: [
                    {
                      visibility: 'on'
                    },
                    {
                      color: '#ffffff'
                    },
                    {
                      lightness: 16
                    }
                  ]
                },
                {
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      saturation: 36
                    },
                    {
                      color: '#333333'
                    },
                    {
                      lightness: 40
                    }
                  ]
                },
                {
                  elementType: 'labels.icon',
                  stylers: [
                    {
                      visibility: 'off'
                    }
                  ]
                },
                {
                  featureType: 'transit',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#f2f2f2'
                    },
                    {
                      lightness: 19
                    }
                  ]
                },
                {
                  featureType: 'administrative',
                  elementType: 'geometry.fill',
                  stylers: [
                    {
                      color: '#fefefe'
                    },
                    {
                      lightness: 20
                    }
                  ]
                },
                {
                  featureType: 'administrative',
                  elementType: 'geometry.stroke',
                  stylers: [
                    {
                      color: '#fefefe'
                    },
                    {
                      lightness: 17
                    },
                    {
                      weight: 1.2
                    }
                  ]
                }
              ]
            }}
          >
            <Marker lat={51.113354} lng={17.052612} />
          </GoogleMapReact>
        </div>
      </SectionContent>
    </Section>
  </Layout>
)

export default Page
